<template>
  <div class="main-container">
    <div class="course-nav">
      <router-link :to="{ name: 'Course' }">选课中心</router-link>
      <span class="slash">></span>
      <span class="current">{{ course.title }}</span>
    </div>
    <div style="position: relative">
      <div class="course-left">
        <div class="course-info">
          <div class="course_ccover">
            <swiper
              class="banner"
              v-if="course.banner.length > 0"
              :options="swiperBannerOption"
              ref="mySwiper1"
            >
              <div
                class="swiper-slide"
                v-for="(item, index) in course.banner"
                :key="index"
              >
                <div
                  class="slider-item"
                  :style="{ backgroundImage: 'url(' + item + ')' }"
                ></div>
              </div>
              <div
                class="swiper-pagination"
                slot="pagination"
                v-if="course.banner.length > 1"
              ></div>
            </swiper>
            <img v-else :src="course.cover" />
          </div>
          <div class="course-detail">
            <div class="clear course-info-title">
              <img
                src="@/assets/images/course/tuan@2x.png"
                v-if="course.spell_price > 0"
                class="f-left"
                style="margin-right: 10px; width: 24px"
              />
              <img
                src="@/assets/images/course/quan@2x.png"
                v-if="couponList.length > 0"
                class="f-left"
                style="margin-right: 10px; width: 24px"
              />
              <span class="couseType"
                >[{{ CouseType.getValue(course.course_type) }}]</span
              >
              <span class="course-title f-left">{{ course.title }}</span>
            </div>
            <div>
              <div class="course-item" v-if="course.course_type != 7">
                <span
                  v-if="
                    course.course_type == 2 ||
                    course.course_type == 3 ||
                    course.course_type == 4
                  "
                >
                  {{ course.start_play_date_str }} -
                  {{ course.end_play_date_str }}
                </span>
                <span
                  v-if="
                    course.course_type == 2 ||
                    course.course_type == 3 ||
                    course.course_type == 4
                  "
                  class="hor-line"
                >
                  |
                </span>
                <span>共</span>
                <span class="count">{{ course.total_periods }}</span>
                <span>个课时</span>
                <span class="hor-line">|</span>
                <span class="count" v-if="course.course_type == 4">
                  {{ course.browse_num }}
                </span>
                <span class="count" v-else>{{ course.sales_num }}</span>
                <span>人已购买</span>
              </div>
              <div v-if="course.expiration_note">
                {{ course.expiration_note }}
              </div>
              <div
                class="course-item"
                v-if="
                  course.course_type == 7 &&
                  course.store_num > 0 &&
                  course.store_num < 999999
                "
              >
                <span>报名上限：{{ course.store_num }}人</span>
                <span class="hor-line">|</span>
                <span>剩余名额：{{ course.stock }}</span>
              </div>
              <div class="course-item" v-if="course.course_type == 7">
                <span>报名截止时间：{{ course.enter_end_date_str }}</span>
              </div>
              <div class="course-item" v-if="course.course_type == 7">
                <span>
                  开课时间：{{ course.start_play_date_str }} -
                  {{ course.end_play_date_str }}
                </span>
              </div>
              <div class="course-item" v-if="course.course_type == 7">
                <span>
                  开课地点：{{
                    course.province_name +
                    course.city_name +
                    course.district_name +
                    course.address
                  }}
                </span>
              </div>
            </div>
            <div
              class="clear activity"
              v-if="
                (course.is_vip_course &&
                  $store.state.commonSetting.switch_vip_module === 1) ||
                couponList.length ||
                gifts.length ||
                course.gifts_integral
              "
            >
              <span class="f-left" style="margin-right: 21px">优惠</span>
              <div class="f-left">
                <div class="clear coupon" v-if="course.gifts_integral">
                  <img
                    class="f-left icon_youhui"
                    src="@/assets/images/jifen.png"
                    style="width: 58px"
                  />
                  <div class="f-left jifen-right">
                    购买可得{{ course.gifts_integral }}积分
                  </div>
                </div>
                <div class="clear coupon" v-if="gifts.length">
                  <img
                    class="f-left icon_youhui"
                    src="@/assets/images/gifts.png"
                    style="width: 58px"
                  />
                  <div class="f-left jifen-right">
                    <div class="actives-item">
                      <span v-for="(item, index) in gifts" :key="index"
                        >{{ getGiftType(item.gift_type) }}
                        <em v-if="index !== gifts.length - 1">、</em>
                      </span>
                      <span class="arrow-right" @click="getGifts">
                        查看 <i class="el-icon-arrow-right"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="clear coupon" v-if="couponList.length">
                  <img
                    class="f-left icon_youhui"
                    src="@/assets/images/youhui.png"
                    style="width: 58px"
                  />
                  <div class="f-left coupon-right">
                    <div
                      class="quan clear"
                      :class="{ used: coupon.is_can_get == 0 }"
                      v-for="coupon in couponList"
                      :key="coupon.id"
                    >
                      <span class="name f-left">
                        满{{ coupon.full_reduction / 100 }}减{{
                          coupon.discounted_price / 100
                        }}
                      </span>
                      <span
                        class="get f-right"
                        v-if="coupon.is_can_get == 1"
                        @click="getCoupon(coupon.id)"
                      >
                        领取
                      </span>
                      <span class="has-get f-right" v-else>已领取</span>
                    </div>
                  </div>
                </div>
                <div
                  class="clear vip"
                  v-if="
                    course.is_vip_course &&
                    $store.state.commonSetting.switch_vip_module === 1
                  "
                >
                  <img
                    class="f-left vip-img"
                    src="@/assets/images/course/vip@3x.png"
                  />
                  <div class="f-left vip-right">
                    <span class="label-free">免费观看</span>
                    <router-link
                      v-if="!course.is_vip_user"
                      :to="{ name: 'MemberVip' }"
                      >开通会员</router-link
                    >
                    <router-link v-else :to="{ name: 'MemberVip' }"
                      >续费会员</router-link
                    >
                    <div v-if="course.is_vip_user" class="vip-end">
                      您的会员到期日为：{{ course.vip_user_end_str }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clear price-container">
              <!-- <span class="f-left right-label">价格：</span> -->
              <span class="price free" v-if="course.is_free">免费</span>
              <span v-else>
                <span class="price">
                  <Currency class="currency-mini"></Currency>
                  <span>{{ course.price | price }}</span>
                </span>
                <strike
                  v-if="course.underlined_price"
                  style="margin-left: 10px"
                >
                  <Currency class="currency-mini"></Currency>
                  <span>{{ course.underlined_price | price }}</span>
                </strike>
              </span>
            </div>
            <div class="study">
              <div v-if="course.is_buy || course.is_join_study">
                <a
                  v-if="course.course_type == 4"
                  @click="goToPlay()"
                  class="btn btn-warning"
                  >立即学习</a
                >
                <a v-else @click="goToStudy" class="btn btn-warning"
                  >立即学习</a
                >
              </div>
              <div v-else-if="course.is_vip_user && course.is_vip_course">
                <img
                  src="@/assets/images/course/vip-study@2x.png"
                  @click="joinStudy"
                  v-if="!course.is_join_study"
                />
                <a
                  v-else
                  @click="
                    $router.push({
                      name: 'MemberStudy',
                      query: { id: $route.params.id, type: 11 },
                    })
                  "
                  class="btn btn-warning"
                  >立即学习</a
                >
              </div>
              <a
                v-else-if="course.course_type == 4"
                @click="goToPlay()"
                class="btn btn-warning"
                >立即学习</a
              >
              <div v-else>
                <div v-if="course.spell_price > 0" class="spell">
                  <div class="btn-buy btn-buy-spell">
                    <div class="price">
                      <Currency class="spell-currency"></Currency>
                      <span>{{ course.spell_price / 100 }}</span>
                    </div>
                    <div class="label">我要开团</div>
                    <div class="spell-qr">
                      <div class="header">手机扫码开团</div>
                      <vue-qr
                        :text="shareCodeUrl"
                        :size="156"
                        class="qrcode"
                      ></vue-qr>
                    </div>
                  </div>
                  <div class="btn-buy" @click="buy">
                    <div class="price">
                      <Currency class="spell-currency"></Currency>
                      <span>{{ course.price / 100 }}</span>
                    </div>
                    <div class="label">单独购买</div>
                  </div>
                  <div style="clear: both"></div>
                </div>
                <div v-else>
                  <a class="btn btn-primary" @click="buy">立即报名</a>
                  <a class="btn consultation" @click="dialogChat = true">
                    咨询客服
                  </a>
                </div>
              </div>

              <div class="collect_share">
                <span class="f-right collect">
                  <span v-if="course.is_collect == 0" @click="collect">
                    <img
                      src="@/assets/images/course/collect-none@2x.png"
                      style="width: 16px; height: 15px"
                    />
                    <span style="vertical-align: middle">收藏</span>
                  </span>
                  <span
                    class="collect-has"
                    v-if="course.is_collect == 1"
                    @click="cancelCollect"
                  >
                    <img
                      src="@/assets/images/course/collect@3x.png"
                      style="width: 14px; height: 15px"
                    />
                    <span>已收藏</span>
                  </span>
                </span>
                <div
                  class="bdsharebuttonbox f-right share"
                  @click="shareCourse"
                >
                  <a
                    href="javascript:void(0)"
                    class="bds_more"
                    data-cmd="more"
                    style="
                      background-size: 14px 14px;
                      background-repeat: no-repeat;
                    "
                    :style="{ backgroundImage: 'url(' + share + ')' }"
                  >
                    <span>分享</span>
                  </a>
                </div>
              </div>
            </div>
            <!-- <ul class="clear teacher"
                v-if="teachers.length<=6">
              <li class="teacher-item"
                  v-for="teacher in teachers"
                  :key="teacher.id"
                  :title="teacher.teacher_name">
                <router-link :to="{name:'ShowTeachersDetaial',params:{id:teacher.teacher_id},query:{route_name:'ShowTeachers',route_label:'名师'}}">
                  <img class="teacher-avatar"
                       :src="teacher.avatar" />
                  <div class="teacher-name">{{teacher.teacher_name}}</div>
                </router-link>
              </li>
            </ul> -->
            <!-- <swiper class="swiper-box teacher"
                    v-if="teachers.length>6"
                    :options="swiperOption">
              <div class="teacher-item swiper-slide"
                   v-for="teacher in teachers"
                   :key="teacher.id"
                   :title="teacher.teacher_name">
                <router-link :to="{name:'OtoTeacherShow',params:{id:teacher.teacher_id}}">
                  <img class="teacher-avatar"
                       :src="teacher.avatar" />
                  <div class="teacher-name">{{teacher.teacher_name}}</div>
                </router-link>
              </div>
            </swiper> -->
          </div>
        </div>
        <div class="course-main f-left">
          <ul
            id="nav"
            class="clear tabs"
            :style="
              leftFixed
                ? 'position:fixed;left:' + leftFixedPosition + 'px;top:84px;'
                : ''
            "
          >
            <li
              class="tab-item"
              :class="{ 'tab-item-current': tab == 0 }"
              @click="changeTab(0)"
              v-if="course.course_type != 4"
            >
              <span>课程介绍</span>
              <div class="tab-line"></div>
            </li>
            <li
              class="tab-item"
              :class="{ 'tab-item-current': tab == 1 }"
              @click="changeTab(1)"
              v-if="course.course_type != 9 && course.course_type != 4"
            >
              <span>课程大纲</span>
              <div class="tab-line"></div>
            </li>
            <li
              class="tab-item"
              :class="{ 'tab-item-current': tab == 2 }"
              @click="changeTab(2)"
              v-if="course.course_type == 5 || course.course_type == 8"
            >
              <span>问答列表</span>
              <div class="tab-line"></div>
            </li>
            <li
              class="tab-item"
              :class="{ 'tab-item-current': tab == 3 }"
              @click="changeTab(3)"
              v-if="course.course_type == 9"
            >
              <span>包含课程</span>
              <div class="tab-line"></div>
            </li>
            <li
              v-if="course.course_type != 9"
              class="tab-item"
              :class="{ 'tab-item-current': tab == 4 }"
              @click="changeTab(4)"
            >
              <span>课程评价（{{ comments.length }}）</span>
              <div class="tab-line"></div>
            </li>
          </ul>
          <div class="tab-content">
            <div
              id="course-detail"
              class="tab-content-detail"
              v-html="course.course_details"
              v-if="tab == 0"
            ></div>
            <Empty
              v-if="course.course_details == '' && tab == 0"
              title="暂无课程介绍信息！"
            />
            <div id="course-chapter" class="tab-content-plan" v-show="tab == 1">
              <CourseChapter :course="course"></CourseChapter>
            </div>
            <div v-if="tab == 3" class="tab-content-courses">
              <ul class="clear">
                <li
                  class="sub-course-item"
                  v-for="course in courses"
                  :key="course.id"
                >
                  <div class="sub-course-title">
                    <router-link
                      :to="{ name: 'CourseShow', params: { id: course.id } }"
                      >{{ course.title }}</router-link
                    >
                  </div>
                  <div class="sub-course-schedule">
                    <span v-if="course.start_play_date">
                      <img
                        class="clock"
                        src="@/assets/images/icon_news_time.png"
                      />
                      <span>{{ course.start_play }}~{{ course.end_play }}</span>
                      <span class="vertical-line">|</span>
                    </span>
                    <span>{{ "共" + course.total_periods + "个课时" }}</span>
                  </div>
                  <div class="sub-course-teacher-list">
                    <div
                      class="sub-course-teacher"
                      v-for="teacher in course.teachers_list"
                      :key="teacher.teacher_id"
                    >
                      <img
                        class="sub-course-teacher-avatar"
                        :src="teacher.avatar"
                      />
                      <div class="sub-course-teacher-name">
                        {{ teacher.teacher_name }}
                      </div>
                    </div>
                  </div>
                  <div class="hor-line"></div>
                  <div class="clear sub-course-footer">
                    <span class="f-left sub-course-sales"
                      >{{ course.sales_num }}人已购买</span
                    >
                    <span class="f-right">
                      <strike v-if="course.underlined_price">
                        <Currency class="currency-mini"></Currency>
                        <span>{{ course.underlined_price | price }}</span>
                      </strike>
                      <span class="sub-course-price">
                        <Currency class="currency"></Currency>
                        <span>{{ course.price | price }}</span>
                      </span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 问答列表 -->
            <div v-if="tab == 2" class="question-box">
              <div v-for="(item, index) in ques_list" :key="index">
                <div class="question-item" @click="toDetail(item)">
                  <div class="question-item-left">
                    <img :src="item.avatar" alt />
                  </div>
                  <div class="question-item-right">
                    <h3 class="question-item-name">
                      {{ item.nickname }}
                      <span>{{ item.created_at }}</span>
                    </h3>
                    <p class="question-item-title">{{ item.ask_title }}</p>
                    <div class="question-item-operate">
                      <span>
                        {{ item.answer_number ? "已回复" : "未回复" }}
                      </span>
                      <span class="from">
                        来自：{{ item.chapter_title }}>{{ item.periods_title }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Empty
                v-if="ques_list.length == 0"
                class="empty"
                title="暂无问答！"
              />
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="5"
                layout="prev, pager, next, total, jumper"
                :total="total"
                v-if="ques_list.length > 0"
              ></el-pagination>
            </div>
            <div v-show="tab == 4" class="tab-content-comment">
              <div
                class="comment-item"
                v-for="comment in comments"
                :key="comment.id"
              >
                <div class="header">
                  <img class="avatar" :src="comment.avatar" />
                  <span class="nickname">{{ comment.nickname }}</span>
                  <div style="position: relative">
                    <el-rate v-model="comment.grade"></el-rate>
                    <div
                      style="
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 1;
                      "
                    ></div>
                  </div>
                </div>
                <div class="content">{{ comment.content }}</div>
                <div class="time">
                  {{
                    new Date(parseInt(comment.created_at) * 1000).format(
                      "yyyy-MM-dd hh:mm"
                    )
                  }}
                </div>
              </div>
              <Empty v-if="comments.length == 0" title="暂无课程评价信息哦！" />
            </div>
          </div>
        </div>
        <div class="teacher_list f-left">
          <p>主讲老师</p>
          <ul class="clear teacher" v-if="teachers.length <= 6">
            <li
              class="teacher-item"
              v-for="teacher in teachers"
              :key="teacher.id"
              :title="teacher.teacher_name"
            >
              <router-link
                :to="{
                  name: 'ShowTeachersDetaial',
                  params: { id: teacher.teacher_id },
                  query: { route_name: 'ShowTeachers', route_label: '名师' },
                }"
              >
                <img class="teacher-avatar" :src="teacher.avatar" />
                <div class="teacher-name">{{ teacher.teacher_name }}</div>
                <div class="teacher-descroption">
                  {{ teacher.introduction }}
                </div>
              </router-link>
            </li>
          </ul>
          <swiper
            class="swiper-box teacher"
            v-if="teachers.length > 6"
            :options="swiperOption"
          >
            <div
              class="teacher-item swiper-slide"
              v-for="teacher in teachers"
              :key="teacher.id"
              :title="teacher.teacher_name"
            >
              <router-link
                :to="{
                  name: 'OtoTeacherShow',
                  params: { id: teacher.teacher_id },
                }"
              >
                <img class="teacher-avatar" :src="teacher.avatar" />
                <div class="teacher-name">{{ teacher.teacher_name }}</div>
              </router-link>
            </div>
            <div
              class="swiper-tea-pagination"
              slot="pagination"
              v-if="teachers.length > 1"
            ></div>
          </swiper>
        </div>
      </div>
      <div
        class="course-right"
        v-if="false"
        id="course_detail_right"
        :style="
          rightFixed == 1
            ? 'position:fixed;right:' + rightFixedPosition + 'px;top:114px;'
            : rightFixed == 2
            ? 'position:absolute;right:0;bottom:0;'
            : ''
        "
      >
        <div class="clear price-container">
          <span class="f-left right-label">价格：</span>
          <span class="price f-right" v-if="course.is_free">免费</span>
          <span v-else class="f-right">
            <strike v-if="course.underlined_price">
              <Currency class="currency-mini"></Currency>
              <span>{{ course.underlined_price | price }}</span>
            </strike>
            <span class="price">
              <Currency class="currency-mini"></Currency>
              <span>{{ course.price | price }}</span>
            </span>
          </span>
        </div>
        <div
          class="clear activity"
          v-if="
            (course.is_vip_course &&
              $store.state.commonSetting.switch_vip_module === 1) ||
            couponList.length
          "
        >
          <span class="f-left">活动：</span>
          <div class="f-left">
            <div class="clear coupon" v-if="couponList.length">
              <img
                class="f-left"
                src="../../images/youhui@3x.png"
                style="width: 58px"
              />
              <div class="f-left coupon-right">
                <div
                  class="quan clear"
                  :class="{ used: coupon.is_can_get == 0 }"
                  v-for="coupon in couponList"
                  :key="coupon.id"
                >
                  <span class="name f-left"
                    >满{{ coupon.full_reduction / 100 }}减{{
                      coupon.discounted_price / 100
                    }}</span
                  >
                  <span
                    class="get f-right"
                    v-if="coupon.is_can_get == 1"
                    @click="getCoupon(coupon.id)"
                    >领取</span
                  >
                  <span class="has-get f-right" v-else>已领取</span>
                </div>
              </div>
            </div>
            <div
              class="clear vip"
              v-if="
                course.is_vip_course &&
                $store.state.commonSetting.switch_vip_module === 1
              "
            >
              <img
                class="f-left vip-img"
                src="@/assets/images/course/vip@3x.png"
              />
              <div class="f-left vip-right">
                <span class="label-free">免费观看</span>
                <router-link
                  v-if="!course.is_vip_user"
                  :to="{ name: 'MemberVip' }"
                  >开通会员</router-link
                >
                <router-link v-else :to="{ name: 'MemberVip' }"
                  >续费会员</router-link
                >
                <div v-if="course.is_vip_user" class="vip-end">
                  您的会员到期日为：{{ course.vip_user_end_str }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="note">
          <div>课程声明：</div>
          <div class="note-detail" v-html="course.course_statement"></div>
        </div>
        <div class="study">
          <div v-if="has_buy || course.is_join_study">
            <a
              v-if="course.course_type == 4"
              @click="goToPlay()"
              class="btn btn-warning"
              >立即学习</a
            >
            <a v-else @click="goToStudy" class="btn btn-warning">立即学习</a>
          </div>
          <div v-else-if="course.is_vip_user && course.is_vip_course">
            <img
              src="@/assets/images/course/vip-study@2x.png"
              @click="joinStudy"
              v-if="!course.is_join_study"
            />
            <a
              v-else
              @click="
                $router.push({
                  name: 'MemberStudy',
                  query: { id: $route.params.id, type: 11 },
                })
              "
              class="btn btn-warning"
              >立即学习</a
            >
          </div>
          <a
            v-else-if="course.course_type == 4"
            @click="goToPlay()"
            class="btn btn-warning"
            >立即学习</a
          >
          <a v-else class="btn btn-primary" @click="buy">立即报名</a>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
    <el-dialog title="分享" :visible.sync="showShareModal" width="400px">
      <vue-qr :text="shareUrl" :size="200" class="center"></vue-qr>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showShareModal = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="赠送商品"
      custom-class="gifts_list"
      :visible.sync="show_gifts"
      width="576px"
      center
    >
      <div v-for="(item, index) in gifts" :key="index" class="gift_item">
        <div class="gift_type">
          <span>{{ getGiftType(item.gift_type) }}</span>
        </div>
        <div class="gift_name">
          <div
            class="name"
            @click="gift_detail(it)"
            v-for="(it, i) in item.list"
            :key="i"
          >
            {{ it.gift_name }}<i v-if="i != item.list.length - 1">、</i>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 课程咨询 -->
    <el-dialog
      class="dialog-consult"
      title="课程咨询"
      :visible.sync="dialogChat"
      width="800px"
      center
    >
      <iframe
        v-if="dialogChat"
        id="frame"
        :src="chatUrl"
        frameborder="0"
      ></iframe>
    </el-dialog>

    <el-dialog
      title="学籍信息登记"
      :visible.sync="course.is_required_enrollment"
      width="30%"
    >
      <Enrollment :course_id="course.id" :onSuccess="onEnrollmentSubmit" />
    </el-dialog>
  </div>
</template>
<script>
import CourseChapter from "../../components/CourseChapter.vue";
import VueQr from "vue-qr";
import { CouseType, ShopType } from "@/utils/enums";
import Enrollment from "@/modules/stu/views/Enrollment.vue";

export default {
  name: "CourseShow",
  components: {
    CourseChapter,
    VueQr,
    Enrollment,
  },
  data() {
    return {
      share: require("@/assets/images/course/share@2x.png"),
      id: "",
      course: {
        is_required_enrollment: false,
        banner: [],
      },
      has_buy: false,
      has_join_spell: false,
      couponList: [],
      gifts: [],
      teachers: [],
      currentTime: "",
      tab: 0,
      courses: [],
      comments: [],
      swiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-tea-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
      },
      scrollTop: 0,
      rightFixed: 0,
      rightTop: 206,
      rightFixedPosition: 0,
      rightFixedBottom: 0,
      rightHeight: 0,
      leftFixed: false,
      leftFixedPosition: 0,
      footbarTop: 0,
      showShareModal: false,
      shareUrl: "",
      CouseType: CouseType,
      show_gifts: false,
      ques_list: [],
      page: 1,
      total: "",
      dialogChat: false,
      chatUrl:
        "https://affim.baidu.com/unique_36355596/chat?siteId=17380588&userId=36355596&siteToken=70d2be3adb568324b2d3436af3616c2b",
    };
  },
  computed: {
    swiperBannerOption() {
      return {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: this.course.banner.length > 1,
        observer: true,
        observeParents: true,
      };
    },
    shareCodeUrl() {
      return (
        this.$store.state.webConfig.H5_address +
        "/course-detail?id=" +
        this.id +
        "&courseType=" +
        this.course.course_type +
        "&share_source=pc"
      );
    },
  },
  async created() {
    await this.getData();
  },
  watch: {
    $route: async function () {
      await this.getData();
    },
  },
  beforeCreate() {
    window._bd_share_main = "";
  },
  mounted() {
    this.$nextTick(() => {
      let $ = window.$;

      let $right = $("#course_detail_right");
      let $footer = $(".footerBar");
      let $left = $(".course-left");
      $(window)
        .scrollTop(0)
        .scroll(() => {
          this.setFixed($right, $footer, $left);
        })
        .resize(() => {
          this.setFixed($right, $footer, $left);
        });
    });
  },
  beforeDestroy() {
    window.$(window).unbind("scroll");
  },
  methods: {
    onEnrollmentSubmit() {
      this.course.is_required_enrollment = false;
    },
    setFixed($right, $footer, $left) {
      let $ = window.$;
      this.rightHeight = $right.height();
      if (this.rightHeight > $left.height()) {
        return;
      }
      this.scrollTop = $(window).scrollTop();
      this.rightFixedPosition = parseInt(($(window).width() - 1200) / 2);
      this.footbarTop = $footer.offset().top;
      this.setRightFixed();
    },
    setRightFixed() {
      let scrollTop = this.scrollTop;
      if (scrollTop == 0) {
        this.rightFixed = 0;
        return;
      }
      if (scrollTop + 224 + this.rightHeight >= this.footbarTop) {
        this.rightFixed = 2;
        return;
      }
      if (scrollTop + 114 > this.rightTop) {
        this.rightFixed = 1;
      } else {
        this.rightFixed = 0;
      }
    },
    async getData() {
      this.id = this.$route.params.id;
      this.tab = this.$route.query.tab || 0;
      this.currentTime = new Date().format("yyyy-MM-dd hh:mm:ss");
      await this.getCourse();
      await this.getCourses();
      await this.getComment();
      await this.getCaskList(1);
    },
    // 获取拼团信息
    async getGroupInfo() {
      let group_info = await this.$http.get(
        `/api/app/spellGroupInfo/${this.id}/course`
      );
      this.course.spell_price = group_info.spell_price;
      if (group_info.spell_id) {
        let group = await this.$http.get(
          "/api/app/spellGroupInfo/" + this.id + "/course"
        );
        if (
          (group.is_join_spell && group.group_status == 1) ||
          (!group.is_join_spell && this.course.is_buy == 1)
        ) {
          this.has_buy = true;
        }
        this.has_join_spell = !!group.is_join_spell;
      } else {
        this.has_buy = this.course.is_buy == 1;
      }
    },
    async getCourse() {
      let data = await this.$http.get(
        "/api/app/courseInfo/basis_id=" + this.id
      );
      data.info.start_play_date_str = new Date(
        data.info.start_play_date * 1000
      ).format("yyyy-MM-dd hh:mm");
      data.info.end_play_date_str = new Date(
        data.info.end_play_date * 1000
      ).format("yyyy-MM-dd hh:mm");
      data.info.enter_end_date_str = new Date(
        data.info.enter_end_date * 1000
      ).format("yyyy-MM-dd hh:mm");
      if (data.info.vip_user_end) {
        data.info.vip_user_end_str = new Date(
          data.info.vip_user_end * 1000
        ).format("yyyy-MM-dd");
      }
      data.info.course_statement = data.info.course_statement.replace(
        /\n/g,
        "<br/>"
      );
      this.course = data.info;
      document.title = this.course.title;
      this.teachers = data.teachers;
      this.couponList = data.couponList;
      this.gifts = data.gifts;
      if (data.info.course_type == 4) {
        this.tab = 3;
      }
      if (this.$store.state.token) {
        this.getShareUrl();
      }
      this.getGroupInfo();
    },
    async getShareUrl() {
      let res = await this.$http.get(
        "/api/app/share/" + this.id,
        { type: 1 },
        true
      );
      this.shareUrl = res.url;
    },
    async getCourses() {
      if (this.course.course_type != 9) {
        return;
      }
      let courses = await this.$http.post("/api/app/sysCourseList", {
        id: this.id,
      });
      courses.forEach((c) => {
        c.start_play = new Date(c.start_play_date * 1000).format(
          "MM月dd日 hh:mm"
        );
        c.end_play = new Date(c.end_play_date * 1000).format("MM月dd日 hh:mm");
        c.teachers_list = c.teachers_list.splice(0, 3);
      });
      this.courses = courses;
    },
    async collect() {
      this.course.collect_id = await this.$http.post(
        "/api/app/collect",
        {
          course_basis_id: this.id,
          type: 1,
        },
        true
      );
      this.course.is_collect = 1;
      await this.$success("收藏成功");
    },
    async cancelCollect() {
      await this.$http.put(
        "/api/app/collect/cancel/" + this.course.collect_id + "/1"
      );
      this.course.is_collect = 0;
      await this.$success("取消收藏成功");
    },
    changeTab(tab) {
      this.tab = tab;
    },
    async joinStudy() {
      await this.$http.post("/api/app/joinStudy", {
        course_basis_id: this.id,
        join_from: 1,
        course_type: this.course.course_type,
      });
      await this.$success("加入学习成功");
      this.course.is_join_study = 1;
    },
    async buy() {
      if (this.has_join_spell) {
        this.$info("您已经参与拼团了，不能单独购买。");
        return;
      }
      if (
        (this.course.course_type == 3 || this.course.course_type == 7) &&
        this.course.store_num > 0 &&
        this.course.stock == 0
      ) {
        this.$alert("你来晚了哦,名额已经没有了", "提示");
        return;
      }
      this.$router.push({
        name: "OrderConfirm",
        params: { id: this.id },
        query: { type: this.course.course_type },
      });
    },
    async getCoupon(id) {
      await this.$http.get("/api/app/userGetCoupon/" + id);
      this.couponList.filter((c) => c.id == id)[0].is_can_get = 0;
      this.$message({
        message: "领取成功",
        type: "success",
      });
    },
    async getComment() {
      let data = await this.$http.post("/api/app/courseComment", {
        id: this.id,
      });
      this.comments = data.list;
    },
    async goToPlay() {
      this.$livePlay(this.id, this.course.course_type, 0);
    },
    async goToStudy() {
      let id;
      let type;
      if (this.course.course_type == 9 && this.courses.length > 0) {
        id = this.courses[0].id;
      } else {
        id = this.$route.params.id;
        type = this.course.course_type;
      }
      this.$router.push({
        name: "MemberStudy",
        query: { id: id, type: type },
      });
    },
    async shareCourse() {
      this.getShareUrl();
      this.showShareModal = true;
    },
    /**
     * 赠品类型
     */
    getGiftType(val) {
      return ShopType.getValue(val);
    },
    /**
     * 显示赠品弹框
     */
    getGifts() {
      this.show_gifts = true;
    },
    // 跳转赠品详情
    gift_detail(item) {
      let param = { name: "BookDetail", params: { id: item.gift_id } };
      if (item.gift_type == 16) {
        //模拟考试
        param.name = "ExamSimulationDes";
      } else if (item.gift_type == 15) {
        //章节练习
        param.name = "ExerciseShow";
      }
      if ((item.gift_type && !item.is_hidden) || item.gift_type != 12) {
        this.$router.push(param);
      }
    },
    // 获取课程详情的问答列表
    getCaskList(page) {
      this.$http
        .get(`/api/app/ask/caskList`, {
          course_id: this.id,
          limit: 5,
          page: page,
        })
        .then((res) => {
          this.ques_list = res.list;
          this.total = res.total;
        });
    },
    //问答列表切换页码
    handleCurrentChange(page) {
      this.getCaskList(page);
    },
    toDetail(item) {
      //未登录跳转登录页，未购买提示先购买课程
      if (!this.$store.state.token) {
        this.$router.push({ name: "Login" });
      } else if (this.course.is_free || this.course.is_buy) {
        this.$router.push({ name: "QuestionShow", params: { id: item.id } });
      } else {
        this.$message.info("请先购买课程");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/course-detail.scss";
</style>
<style lang="scss">
.banner {
  height: calc(100vw * 365 / 1920);
  overflow: hidden;
  .slider-item {
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
#course-detail {
  overflow: hidden;
  img {
    max-width: 100%;
    vertical-align: bottom;
  }
}
.bdshare_popup_box {
  width: 116px !important;
}
.bdshare_popup_list li {
  display: none;
  &:nth-child(2),
  &:nth-child(5),
  &:nth-child(14) {
    display: block;
  }
}
.bdshare_popup_bottom {
  display: none;
}
.bdshare_dialog_bg,
.bdshare_dialog_box {
  display: none !important;
}
.gifts_list {
  .el-dialog__header {
    border-bottom: 1px solid #f6f6f6;
  }
  .gift_item {
    display: flex;
    margin-bottom: 20px;
    .gift_type {
      color: #333333;
      font-size: 12px;
      width: 70px;
      text-align: right;
      margin-right: 10px;
      span {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        padding: 0 7px;
        background: linear-gradient(
          125deg,
          rgba(240, 200, 159, 1),
          rgba(194, 144, 95, 1)
        );
        border-radius: 3px;
      }
    }
    .gift_name {
      width: 420px;
      .name {
        cursor: pointer;
      }
    }
  }
}
.dialog-consult {
  /deep/ {
    .el-dialog {
      margin-top: 30px !important;
    }
    .el-dialog__body {
      padding: 5px;
    }
  }
}

#frame {
  display: block;
  height: 600px;
  width: 100%;
}
</style>
