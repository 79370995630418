<template>
  <div>
    <swiper
      class="banner"
      v-if="bannerList.length > 0"
      :options="swiperOption"
      ref="mySwiper1"
    >
      <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
        <a :href="item.link_content" v-if="item.link_type == 1">
          <div
            class="slider-item"
            :style="{ backgroundImage: 'url(' + item.banner_img + ')' }"
          ></div>
        </a>
        <!-- <router-link
          :to="{name:'CourseShow',params:{id:item.link_content}}"
          v-else-if="item.link_type==2"
        >
          <div class="slider-item" :style="{backgroundImage:'url('+item.banner_img+')'}"></div>
        </router-link> -->
        <a
          style="cursor: pointer"
          v-else-if="item.link_type == 2"
          @click="bannerClick(item)"
        >
          <div
            class="slider-item"
            :style="{ backgroundImage: 'url(' + item.banner_img + ')' }"
          ></div>
        </a>
        <router-link
          :to="{ name: 'NewsShow', params: { id: item.link_content } }"
          v-else-if="item.link_type == 3"
        >
          <div
            class="slider-item"
            :style="{ backgroundImage: 'url(' + item.banner_img + ')' }"
          ></div>
        </router-link>
        <router-link
          :to="{ name: 'BookDetail', params: { id: item.link_content } }"
          v-else-if="item.link_type == 4"
        >
          <div
            class="slider-item"
            :style="{ backgroundImage: 'url(' + item.banner_img + ')' }"
          ></div>
        </router-link>
        <router-link
          :to="{ name: 'ExerciseShow', params: { id: item.link_content } }"
          v-else-if="item.link_type == 5"
        >
          <div
            class="slider-item"
            :style="{ backgroundImage: 'url(' + item.banner_img + ')' }"
          ></div>
        </router-link>
        <router-link
          :to="{ name: 'ExamSimulationDes', params: { id: item.link_content } }"
          v-else-if="item.link_type == 6"
        >
          <div
            class="slider-item"
            :style="{ backgroundImage: 'url(' + item.banner_img + ')' }"
          ></div>
        </router-link>
        <div
          class="slider-item"
          :style="{ backgroundImage: 'url(' + item.banner_img + ')' }"
          v-else-if="item.link_type == 0"
        ></div>
      </div>
      <div
        class="swiper-pagination"
        slot="pagination"
        v-if="bannerList.length > 1"
      ></div>
    </swiper>
    <div class="main-container">
      <!-- <div class="navad" v-if="$store.state.user_info.id">
        <div class="sec-nav first" @click="goTo('book')">
          <span>图书</span>
        </div>
        <div class="sec-nav second" @click="goTo('exercise')">
          <span>章节练习</span>
        </div>
        <div class="sec-nav third" @click="goTo('exam')">
          <span>模拟考试</span>
        </div>
        <div class="sec-nav four" @click="goTo('question')">
          <span>问答</span>
        </div>
      </div> -->
      <div class="index_card">
        <div
          v-for="(item, index) in index_cards"
          :key="index"
          @click="skipPage(item)"
        >
          <img :src="item.icon" />
          <div>{{ item.name }}</div>
          <span class="line"></span>
        </div>
      </div>
      <div class="select-tab">
        <Classify
          ref="classify"
          :classify_id="classify_id"
          :all="true"
          @changeClassify="changeClassify"
        />
        <div class="item">
          <div class="left">
            <p>类型：</p>
          </div>
          <div class="right">
            <ul>
              <li
                :class="item.key == courseType ? 'active' : ''"
                v-for="item in courseTypes"
                :key="item.key"
                @click="changeType(item.key)"
              >
                {{ item.value }}
              </li>
            </ul>
          </div>
        </div>
        <div class="item" v-for="attr in attrs" :key="attr.id">
          <div class="left" :title="attr.name">
            <p>{{ attr.name }}：</p>
          </div>
          <div class="right">
            <ul>
              <li
                :class="attrCondition[attr.id] == item.id ? 'active' : ''"
                v-for="item in attr.child"
                :key="item.id"
                @click="checkAttr(attr.id, item.id)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="course-list">
        <CourseItem
          v-for="course in courses"
          :key="course.id"
          :course="course"
        ></CourseItem>
        <div style="clear: both"></div>
      </div>
      <Empty
        v-if="courses.length == 0"
        class="empty"
        title="暂无课程安排，敬请期待！"
      />
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="limit"
        layout="prev, pager, next, total, jumper"
        :total="total"
        v-if="courses.length > 0"
      ></el-pagination>
    </div>

    <el-dialog
      class="dialog-consult"
      title="咨询客服"
      :visible.sync="dialogChat"
      width="800px"
      center
    >
      <iframe v-if="dialogChat" id="frame" :src="chatUrl" frameborder="0" />
    </el-dialog>
  </div>
</template>
<script>
import { SearchCouseType } from "@/utils/enums";
import CourseItem from "@/components/CourseItem.vue";
import Classify from "../../components/Classify.vue";

export default {
  name: "Course",
  components: { CourseItem, Classify },
  data() {
    return {
      dialogChat: false,
      chatUrl:
        "https://affim.baidu.com/unique_36355596/chat?siteId=17380588&userId=36355596&siteToken=70d2be3adb568324b2d3436af3616c2b",
      courseTypes: [],
      bannerList: [],
      classifyList: [],
      classify: 0,
      subClassify: 0,
      courseType: 0,
      limit: 12,
      page: 1,
      total: 0,
      courses: [],
      attrCondition: {},
      attrs: [],
      classify_id: 0,
      childClassify: [],
      index_cards: [
        {
          name: "免费刷题（真题）",
          routeName: "Exercise",
          icon: require("@/assets/images/index-exam@2x.png"),
        },
        {
          name: "金牌讲师",
          routeName: "ShowTeachers",
          icon: require("@/assets/images/index-teacher@2x.png"),
        },
        {
          name: "APP下载",
          routeName: "Download",
          icon: require("@/assets/images/index-app@2x.png"),
        },
        {
          name: "咨询客服",
          routeName: "",
          icon: require("@/assets/images/index-service@2x.png"),
        },
      ],
    };
  },
  computed: {
    keywords() {
      return this.$store.state.search;
    },
    subClassifyList() {
      let firstClassify = this.classifyList.filter(
        (c) => c.id == this.classify
      )[0];
      if (!firstClassify) {
        return [];
      }
      return firstClassify.children;
    },
    swiperOption() {
      return {
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: this.bannerList.length > 1,
        observer: true,
        observeParents: true,
      };
    },
  },
  watch: {
    keywords: async function () {
      await this.getCourse();
    },
  },
  async created() {
    await this.getBanner();
    await this.getClassify();
    await this.getCourse();
  },
  methods: {
    goTo(type) {
      let routeName = {
        book: "Book",
        exam: "ExamSimulation",
        question: "Question",
        exercise: "Exercise",
      };
      this.$router.push({
        name: routeName[type],
      });
    },
    async getBanner() {
      this.bannerList = await this.$http.get("/api/app/banner");
    },
    async getClassify() {
      let data = await this.$http.get("/api/app/courseClassify");
      let attrs = data.attrclassify;
      attrs.forEach((attr) => {
        attr.child.unshift({ id: 0, name: "全部" });
        this.$set(this.attrCondition, attr.id, 0);
      });
      this.attrs = attrs;
      let courseTypes = [{ key: 0, value: "全部" }];
      data.courseTypes.forEach((t) => {
        courseTypes.push({ key: t, value: SearchCouseType.getValue(t) });
      });
      if (data.vipCourseCount > 0) {
        courseTypes.push({ key: "-1", value: "会员课" });
      }
      this.courseTypes = courseTypes;
    },
    async check_classify(id, type) {
      this.page = 1;
      if (type == 1) {
        this.classify = id;
        this.subClassify = 0;
      } else {
        this.subClassify = id;
      }
      await this.getCourse();
    },
    async changeType(type) {
      this.page = 1;
      this.courseType = type;
      await this.getCourse();
    },
    async changeClassify(flag, item) {
      if (flag == 1) {
        this.classify_id = item.id;
        this.subClassify = null;
        this.$refs.classify.showChildClassify = true;
        this.$nextTick(() => {
          this.$refs.classify.childClassify = item.child;
        });
      } else {
        this.subClassify = item.id;
      }
      this.page = 1;
      await this.getCourse();
    },
    async getCourse() {
      let params = {
        course_type: this.courseType > 0 ? this.courseType : 0,
        classify_id: this.subClassify || this.classify_id,
        // classify_id: this.classify_id,
        is_vip: this.courseType == -1 ? 1 : 0,
        keywords: this.keywords,
        limit: this.limit,
        page: this.page,
      };
      let attrValIds = [];
      for (let key in this.attrCondition) {
        let valId = this.attrCondition[key];
        if (valId != 0) {
          attrValIds.push(valId);
        }
      }
      params.attr_val_id = attrValIds.join(",");
      this.courses = [];
      let data = await this.$http.get("/api/app/courseBasis", params);
      let courses = data.list;
      courses.forEach((c) => {
        c.start_play = new Date(c.start_play_date * 1000).format(
          "MM月dd日 hh:mm"
        );
        c.end_play = new Date(c.end_play_date * 1000).format("MM月dd日 hh:mm");
        c.teachers_list = c.teachers_list.splice(0, 3);
      });
      this.courses = courses;
      this.total = data.total;
    },
    async handleCurrentChange(page) {
      this.page = page;
      await this.getCourse();
    },
    async checkAttr(attrId, attrValId) {
      this.attrCondition[attrId] = attrValId;
      this.page = 1;
      await this.getCourse();
    },
    skipPage(item) {
      if (item.routeName) {
        this.$router.push({ name: item.routeName });
      } else {
        //唤起客服
        this.dialogChat = true;
      }
    },
    async bannerClick(item) {
      if (item.course_type == 4) {
        let info = await this.$http.get(
          `/api/app/getPcRoomCode/course_id=${item.course_id}/chapter_id=0`
        );
        if (!localStorage.studentToken) {
          //未登录
          let timestamp = new Date().getTime();
          localStorage.setItem("start_time", timestamp);
        }
        this.$router.push({
          name: "PublicCoursePlay",
          query: { src: info.chapterInfo.web_url },
        });
      } else {
        this.$router.push({
          name: "CourseShow",
          params: { id: item.link_content },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.empty {
  margin-bottom: 30px;
}

.banner {
  height: calc(100vw * 400 / 1920);
  overflow: hidden;
  .slider-item {
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

/deep/ .el-pagination {
  text-align: center;
  margin: 23px 0 50px 0;
}

/deep/ .el-pagination.is-background .el-pager .number {
  background-color: white;
}

.course-list {
  padding-top: 31px;
}

.navad {
  margin-top: 20px;
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  .sec-nav {
    width: 285px;
    height: 200px;
    text-align: center;
    padding-top: 40px;
    background-repeat: no-repeat;
    background-position: bottom center;
    cursor: pointer;
    &.first {
      color: #683cae;
      background-color: #c7a8f8;
      background-image: url("../../images/home-first.png");
    }
    &.second {
      color: #34644b;
      background-color: #9fdfc1;
      background-image: url("../../images/home-second.png");
    }
    &.third {
      color: #902820;
      background-color: #ee6e68;
      background-image: url("../../images/home-third.png");
    }
    &.four {
      color: #745a22;
      background-color: #f7d684;
      background-image: url("../../images/home-four.png");
    }
  }
}
// banner图底部的四个模块
.index_card {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 6px;
  > div {
    width: 288px;
    height: 205px;
    background-color: #ffffff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #626262;
    font-size: 19px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    cursor: pointer;
    img {
      width: 57px;
      height: 57px;
      margin-bottom: 25px;
    }
    .line {
      width: 21px;
      height: 2px;
      background-color: #0090ff;
      margin-top: 13px;
    }
  }
}

.dialog-consult {
  /deep/ {
    .el-dialog {
      margin-top: 30px !important;
    }
    .el-dialog__body {
      padding: 5px;
    }
  }
}

#frame {
  display: block;
  height: 600px;
  width: 100%;
}
</style>
